<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'BusinessPartnerInfo',

    metaInfo() {
      return {
        title: this.$t('section.business_partner_info'),
        meta: [
          { name: 'description', content: this.$t('section.business_partner_info_description') },
        ],
      };
    },

    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        'business-partner-landing',
        'request-demo',
        'customers',
        'info',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'business-partner',
      },
    },

    mounted() {
      window.prerenderReady = true;
    }

  }
</script>
